<template>
  <div class="login-wrap">
    <div class="login-container">
      <div class="login-logo">
        <div class="logo-containers">
          <div>
            <img :src="loginLogo" alt="">
          </div>
        </div>
      </div>
      <div class="login-form-container">
        <div class="pic">
          <!-- <img class="img" src="@/assets/logo.png" alt=""> -->
        </div>
        <div class="login-form">
          <div class="title">
            登录/注册
          </div>
          <div class="form">
            <div class="contact-item">
              <div class="phone">
                <a-select 
                   v-model="countryId" 
                   show-search 
                   placeholder="选择区号" 
                   dropdownClassName="drop"
                  class="login-select">
                  <a-icon style="margin-top:-4px" slot="suffixIcon" type="caret-down" :style="{ fontSize: '16px' }" />
                  <a-select-option v-for="item in country" :key="item.id" :label="item.name" :value="item.id+'-'+item.timezone">
                    {{ item.name }}&nbsp;&nbsp;+{{ item.timezone }}
                  </a-select-option>
                </a-select>
                <div class="cut-off-rule"></div>
                <input v-model="phone" class="input" placeholder="请输入手机号" />
              </div>
            </div>
            <div class="contact-item">
              <input v-model="code" class="input" placeholder="请输入验证码" />
              <a-button @click="sendCode()" :class="disabled?'disabled_btn':'code-btn' " :disabled="disabled">{{ btnTitle }} </a-button>
            </div>
            <div class="contact-item">
              <input v-model="invite_id" class="input" placeholder="请输入邀请码(选填)" />
            </div>
            <div class="login-btn">
              <button class="btn" @click="login">登录/注册</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
export default {
  data () {
    return {
      phone: '',
      code: '',
      invite_id:'',
      btnTitle: '获取验证码',
      btnDisabled: false,
      disabled: false,
      countryId: undefined,
      country: [
        {
          label: '',
          value: ''
        }
      ],
      loginLogo:require('@/assets/smartcat/loginLogo.svg')
    }
  },

  mounted () {


  },
  created () {
    this.getCountry()
  },
  methods: {
    //区号
    getCountry () {
      this.$request.country().then(res => {
        if (res.ret) {
          this.country = res.data
        }
      })
    },
    sendCode () {
      if (this.phone == '') {
        return this.$message.error('请输入手机号');
      } else if (this.countryId == undefined) {
        return this.$message.error('请选择区号');
      }
      let params = {
        receiver: this.countryId.split('-')[1] + this.phone
      }
      this.$request.register(params).then(res => {
        if (res.ret) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + '秒后重试';
          this.disabled = true;
          time--
        }
      }, 1000)
    },
    login () { 
      if (this.phone == '') {
        return this.$message.error('请输入手机号');
      } else if (this.countryId == undefined) {
        return this.$message.error('请选择区号');
      } else if (this.code == '') {
        return this.$message.error('请输入验证码');
      }
      let params = {
        phone: this.phone,
        code: this.code,
        invite_id:this.invite_id,
        area_code:this.countryId.split('-')[1],
      }
      if(localStorage.getItem('source')){
        params.source=localStorage.getItem('source')
      }
      this.$request.login(params).then(res => {
        if (res.ret) {
          const hostName = location.hostname
          switch (hostName) {
              case 'smartcatplus.com':
              case 'release.smartcatplus.com':
              window.location.href =
                "https://jiyun-v2.smartcatplus.com/#/login/?key=" +
                 res.data.key 
              break
              case 'test.smartcatplus.com':
              window.location.href =
                "https://admin-test.smartcatplus.com/#/login/?key=" +
                 res.data.key 
              break
            default:
            window.location.href = 
                 "http://localhost:8080/#/login/?key=" +
                 res.data.key  
              break
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}

</script>
  
<style lang="scss" scoped>
/deep/.ant-select-selection__placeholder {
  margin-left: 5px;
}

/deep/.ant-select-selection {
  border: none !important;
  box-shadow: none;
}

/deep/.login-select .ant-select-selection--single {
  width: 120px;
  height: 36px;
  border-radius: 20px 0px 0 20px;
  background-color: #fff;
  padding-top: 2px;
  border-right: 0;
}
input::-webkit-input-placeholder {
  color: #ccc
       
    }
.login-wrap {
  min-width: 1200px;
  overflow-x: hidden;
  overflow-y: auto;

  .login-container {
    display: flex;
    min-height: 100vh;
    font-size: 14px;
    line-height: 20px;
    // background-color: #ffd000;

    .login-logo {
      position: relative;
      width: 67.5%;
      background: url(~@/assets/smartcat/bg.svg) center / cover no-repeat;

      .logo-containers {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        margin-top: -3%;
        transform: translate(-50%, -50%);
      }
    }

    .login-form-container {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 32.5%;

      .pic {
        .img {
          width: 363px;
          height: 100px;
        }
      }

      .login-form {
        margin-top: 50px;

        .title {
          margin-top: 41px;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 28px;
          color: rgba(0, 0, 0, 1);

        }

        .form {

          .contact-item {
            margin: auto;
            margin-top: 40px;
            position: relative;
            width: 360px;
            border: 1px solid rgba(204, 204, 204, 1) !important;
            border-radius: 22px;

            .input {
              height: 36px;
              padding-left: 20px;
              border-width: 0px;
              outline: none;
              border-radius: 20px 0px 0 20px;
            }

            .code-btn {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 120px;
              height: 36px;
              border-radius: 0px 18px 18px 0px;
              background: rgba(255, 208, 0, 1);
              color: #000;
              font-size: 14px;
              border: none;
              cursor: pointer;
            }
            .disabled_btn {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 120px;
              height: 36px;
              border-radius: 0px 18px 18px 0px;
              background: #ccc;
              color: #fff;
              font-size: 14px;
              border: none;
              cursor: pointer;
            }
            .code-btn:hover {
              // background-color: #000;
              // color: #fff; 

            }

            .phone {
              display: flex;
              align-items: center;
              width: 360px;
              height: 36px;
              line-height: 36px;

              .cut-off-rule {
                width: 1px;
                height: 24px;
                opacity: 1;
                background: rgba(204, 204, 204, 1);
                line-height: 4px;
              }


            }
          }

          .login-btn {
            margin-top: 30px;

            .btn {
              margin-top: 10px;
              width: 360px;
              height: 36px;
              border-radius: 18px;
              background-color: #ffd000;
              border: 0;
              cursor: pointer;
            }

            .btn:hover {
              background-color: #000;
              color: #fff;
            }
          }
        }
      }

    }
  }
}
</style>
<style >
ul>.ant-select-dropdown-menu-item-active {
  background: #eee !important;
}
</style>

